/* CSS for treat-me (cost) */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

body {
  font-family: @sans-serif;
  font-weight: 300;
  color: @color-dark;
}

h1,
h2,
h3,
h4,
h5,
h5,
h6 {
  font-family: @serif;
  font-weight: 300;
}

.vrtx-introduction {
  font-family: @serif;
}

.vrtx-search-results-container div.vrtx-search-results .result h2 a,
a {
  color: @color-link;
}

a.vrtx-title {
  color: @color-link !important;
  font-weight: 300 !important;
  font-family: @serif;
}

.grid-container .vrtx-frontpage-box h2 a {
  color: @color-link;
}

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not(.undo):not(.x-cancel):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]) {
  background-color: @color-primary !important;
}

.not-for-ansatte {
  .header-search-expand,
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not(.undo):not(.x-cancel):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red),
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  input[type="cancel"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red) {
    background-color: @color-primary !important;
    .transition(all, 0.5s, ease-in);
    color: @color-light;
    &:focus,
    &:hover {
      background-color: @color-primary--dark !important;
    }
  }

  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }

  #head-wrapper {
    background: @color-secondary;
    .uio-app-name {
      display: none;
    }
    #head {
      height: 200px;
      padding-top: 35px;
      padding-left: 14px;
      .head-menu {
        .language {
          display: none;
        }
        .vrtx-login-manage-component
          a:not(.vrtx-login-manage-admin):not(.vrtx-login-manage-logout):not(.vrtx-dropdown-component
            .vrtx-dropdown-wrapper):not(.vrtx-login-manage-edit):not(.vrtx-login-manage-admin-collection):not(.vrtx-login-manage-edit-collection) {
          color: @color-light;
        }
      }

      #header {
        color: @color-light;
        .logo {
          img {
            width: 28%;
          }
          .logo-text {
            margin-top: 10px;
            font-family: @sans-serif;
            font-size: 18px;
            font-size: 1.8rem;
            line-height: 2.8rem;
            color: @color-header-logo;
          }
          a:focus,
          a:hover {
            text-decoration: underline;
          }
        }
      }
      .header-search input[type="text"] {
        background: @color-text-field;
        color: @color-neutral--dark;
      }
    }
  }

  #globalnav {
    background: @color-primary none repeat scroll 0 0;
    ul li {
      &.vrtx-active-item a,
      a {
        font-family: @serif;
        color: @color-light;
        &:focus,
        &:hover {
          background: @color-contrast none repeat scroll 0 0;
          color: @color-dark;
          margin-top: 0;
        }
      }
      &.vrtx-active-item a {
        background: @color-light none repeat scroll 0 0;
        color: @color-neutral--dark;
      }
    }
  }

  #left-main ul.vrtx-breadcrumb-menu li {
    a.vrtx-marked {
      background-color: @color-secondary !important;
    }
  }

  .footer > .vrtx-search-scope a::before,
  a.all-comments::before,
  a.all-messages::before,
  a.more-url::before,
  a.vrtx-more::before,
  div.vrtx-more a::before {
    background: url("../images/arrow-forward-blue.svg") no-repeat 0 3px !important;
  }

  .ui-accordion.ui-widget {
    .ui-accordion-header .ui-icon {
      background: transparent url("../images/icon-chevron-right-blue.svg")
        no-repeat center left;
    }
    .ui-accordion-header.ui-accordion-header-active .ui-icon {
      background-image: url("../images/icon-chevron-down-blue.svg");
    }
  }
  #main .vrtx-back a:before {
    background: url("../images/icon-chevron-back-blue.svg") no-repeat 0 0.2ex;
  }

  #main {
    .row-all-colored,
    .vrtx-frontpage-box.grey-box,
    .vrtx-frontpage-box.grey-box-light,
    .vrtx-frontpage-box.grey-clip-box {
      background: @color-neutral--light;
    }

    .vrtx-frontpage-box {
      &.flaggskip {
        background-color: @color-light;
        padding: 20px 0;
        width: 49%;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 #999999;
        &.left-top {
          margin: 30px 10px 10px 0;
        }
        &.right-top {
          margin: 30px 0 10px;
          float: right;
        }
        &.left-middle {
          margin: 20px 0 10px;
        }
        &.right-middle {
          margin: 20px 0 10px;
          float: right;
        }
        &.left-bottom {
          margin: 20px 0 30px;
        }
        &.right-bottom {
          margin: 20px 0 30px;
          float: right;
        }
        &.left-bottom,
        &.left-middle,
        &.left-top {
          img {
            padding-left: 14px;
          }
        }
        &.right-bottom,
        &.right-middle,
        &.right-top {
          img {
            padding-right: 14px;
          }
        }
        img {
          top: 50%;
        }
        p {
          padding-right: 20px;
        }
        h2 {
          font-size: 18px;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1.5;
          padding-top: 0 !important;
        }
      }
      &.not-flagship {
        padding: 20px 0;
        width: 49%;
        margin: 20px 0 30px;
        float: right;
      }
    }
  }

  #bottomnav {
    display: none;
  }

  #footer-wrapper {
    background: @color-secondary;
    *,
    a {
      color: @color-light;
    }

    #footers {
      background: url("../images/uiologo.png") no-repeat 0 15px;

      .social-components {
        padding: 10px 0 30px;
        margin-left: 0;
        margin-top: -10px;
        margin-bottom: 20px;
        a {
          background-size: 100%;
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center center;
          &.facebook,
          &.twitter-x {
            color: transparent;
            margin-left: 3px;
            margin-right: 10px;
            padding: 0 0 34px 50px;
            height: 50px;
            width: 50px;
            border-radius: 50px;
            &:focus,
            &:hover {
              color: transparent;
              opacity: 0.6;
            }
          }
          &.facebook {
            background-image: url("../uio1/images/social-list/black-svg/facebook.svg");
          }
          &.twitter-x {
            background-image: url("../uio1/images/social-list/black-svg/twitter-x.svg");
          }
        }
      }

      .cost-logo a {
        &:focus,
        &:hover {
          color: transparent;
          box-shadow: none;
          background-color: transparent;
        }
      }
    }
    .col-3-5 {
      width: 70%;
    }
    .col-2-5.responsible-login {
      width: 30%;
      .content {
        margin-left: 65px;
      }
    }
    #eu-flag-wrapper {
      display: block;
      background: @color-secondary;
      width: 100%;
      #eu-flag {
        padding: 15px 0;
        font-size: 16px;
        font-size: 1.6rem;
        width: 100%;
        a {
          float: left;
        }
        img {
          padding-right: 10px;
        }
      }
    }

    &.grid-container {
      padding: 30px 0 0;
    }
  }
}
@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #head-wrapper {
      #head {
        padding: 14px;
        #header {
          .logo {
            img {
              width: 100%;
            }
            .logo-text {
              font-size: 14px;
              font-size: 1.4rem;
              line-height: 2rem;
              line-height: 20px;
            }
          }
        }
      }
    }

    #menu-wrapper {
      .menu,
      .menu li.active {
        background: @color-primary none repeat scroll 0 0;
      }
    }
    #lang-link a {
      display: none !important;
    }
    #footer-wrapper {
      .responsible-login .content {
        margin-left: 0;
      }
    }
  }

  #vrtx-frontpage #main {
    .vrtx-frontpage-box {
      &.flaggskip {
        margin-bottom: 10px;
        background-color: @color-light;
        padding: 20px 0;
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        margin-top: 10px !important;
        border-top-width: 0 !important;

        &.left-bottom,
        &.left-middle {
          margin-top: 0 !important;
        }
      }
    }

    .grid-container .row .half-box-left {
      margin-top: -15px;
    }
    .grid-container.row-last-colored + .row-first-colored {
      border-top: none;
    }
  }
}
/*------------------------------------*\
# COLOR
\*------------------------------------*/
// Primary, secondary etc...
@color-primary: #2998b5;
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-secondary: #003e60;
@color-contrast: #f6e444;
@color-link: #077794; // Neutral colors
@color-neutral--dark: #444444;
@color-neutral--light: #f4f4f4;
@color-light: #ffffff;
@color-dark: #2b2b2b;
@color-text-field: #f3f3f3;
@color-header-logo: #c4dbe6;
/*------------------------------------*\
# FONT
\*------------------------------------*/
@sans-serif: "Roboto", sans-serif;
@serif: "Roboto Slab", serif;
